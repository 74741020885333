<template>
  <div>
    <h1 class="font-medium text-lg mb-20 px-16 sm:px-0">User Edit</h1>

    <div class="flex flex-col sm:flex-row items-start">
      <div class="flex-1 order-2 sm:order-1 w-full sm:w-auto">
        <edit-profile
          :user-data="userData"
          :loading="buttonLoading"
          class="mb-20"
          @edit-user="onEditUser"
        />

        <update-password
          :user-data="userData"
          :loading="buttonLoading"
          @update-password="onUpdatePassword"
        />
      </div>

      <el-button
        type="primary"
        :loading="buttonLoading"
        class="ml-auto sm:ml-24 mb-20 sm:mb-0 mr-16 sm:mr-0 order-1 sm:order-2"
        @click="$router.push({ name: 'AdminUsers' })"
      >
        Back to user list
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserService from '@/services/user.service'

const EditProfile = () => import('@/components/admin-users/EditProfile')
const UpdatePassword = () => import('@/components/admin-users/UpdatePassword')
const ElButton = () => import('@/components/shared/ELButton')

export default {
  name: 'AdminUserEditPage',

  components: { EditProfile, UpdatePassword, ElButton },

  data () {
    return {
      buttonLoading: false,
      userData: {
        email: '',
        full_name: '',
        role: ''
      }
    }
  },

  created () {
    this.firstLoad()
  },

  methods: {
    ...mapActions(['loading', 'showNotification']),

    firstLoad () {
      UserService.getAdminUserById(this.$route.params.id)
        .then(res => {
          this.userData = res
          this.loading(false)
        })
    },

    onEditUser () {
      this.buttonLoading = true
      UserService.editAdminUser({
        id: this.userData.id,
        username: this.userData.username,
        full_name: this.userData.full_name,
        role: this.userData.role,
        email: this.userData.email,
        enable_order_email: this.userData.enable_order_email
      })
        .then(res => {
          this.userData = res
          this.showNotification({ type: 'success', message: 'User updated.' })
          this.buttonLoading = false
        })
        .catch(() => {
          this.buttonLoading = false
        })
    },

    onUpdatePassword ({ newPassword, isNotify }) {
      this.buttonLoading = true
      UserService.updateAdminUserPassword({ email: this.userData.email, password: newPassword, notifyUser: isNotify })
        .then(res => {
          this.buttonLoading = false
          this.showNotification({ type: 'success', message: 'Your password has been successfully updated.' })
        })
        .catch(() => {
          this.buttonLoading = false
        })
    }
  }
}
</script>
